import { action as mobxAction, makeAutoObservable, toJS, observable, computed } from "mobx"
import { FullS3DomainOptions, GlobalConfig, IMenu, MenuItem, WorksStoreProps } from "./types"
import globalData from "./globalData"
import globalEnumInside from "./globalEnumInside"
import Api from "@/server"
import { get, getCompanyCodeStr, set } from "@/utils/tools"
import * as 枚举值 from "@/types/ConstantRecord"
import pageControl from "@/utils/Cache/pageControl"
import { replaceDoubleSlashes, webpSupport } from "./tools"
import errorReport from "@/utils/sentry"
import { baseRouters } from "@/Router/router"
import { EventEmitter } from "@/utils/event"

export class WorksStore {
    constructor(props: WorksStoreProps) {
        makeAutoObservable(this)
    }

    @observable defaultCurrency: string = "CNY"

    @observable supportWebp = webpSupport()

    @observable token: string = ""

    @observable companyCode: string = ""

    @observable merchantCode: string = ""

    @observable merchantList: Array<any> = []

    @observable currentMerchantInfo: any = {}

    @observable isOrganizationAdmin: boolean = false

    @observable isPlatformAdmin: boolean = false

    @observable isLogin: boolean = false

    @observable tagWidth: number = 600

    @observable tagWidthHalf: number = 300

    @observable userInfo: Record<string, any> = {}

    // 控制页面开发用数据显示隐藏的值
    @observable _showField: boolean = false
    // 用来判断是否拉取用户信息已完成
    @observable _isInitUserInfoFinish: boolean = false

    @observable menus: Array<IMenu> = []

    @observable globalEnum: Record<string, any> = globalData
    @observable globalEnumInside: Record<string, any> = globalEnumInside
    @observable globalEnumKV: Record<string, any> = {}

    @observable isAllPerm = false

    @observable globalConfig: GlobalConfig = {
        // imageDomain: "https://ccff-img.s3.ap-east-1.amazonaws.com/",
        imageDomain: "https://ccff-public-img.s3.ap-east-1.amazonaws.com/",
        fileDomain: "https://ccff-excel-upload.s3.ap-east-1.amazonaws.com/",
    }

    @observable notificationCount = 0
    // 出入款count标志
    @observable withdrawCount: number = 0
    @observable depositOnlineCount: number = 0
    @observable depositOfflineCount: number = 0

    /** 用户的权限 */
    @computed get permMap() {
        const userInfo = this.userInfo
        const permIds = get(userInfo, "permCodes", [])
        return permIds.reduce((acc: Record<string, boolean>, cur: string) => {
            acc[cur] = true
            return acc
        }, {})
    }

    @mobxAction
    setAllPerm = () => {
        const routers = Object.values(globalEnumInside.权限枚举值)
        set(this.userInfo, "permCodes", routers)
    }

    @mobxAction
    switchField = () => {
        this._showField = !this._showField
    }

    @mobxAction
    setInitUserInfoFinish = (result: boolean) => {
        this._isInitUserInfoFinish = result
    }

    @mobxAction
    init = (arr: Array<IMenu>) => {
        this.menus = arr
    }

    @mobxAction
    initStore = () => {
        pageControl.destory()
        const companyCodeStr = getCompanyCodeStr()
        this.token = localStorage.getItem("token") || ""
        this.companyCode = (
            localStorage.getItem("companyCode") ||
            companyCodeStr ||
            ""
        ).toUpperCase()
        this.merchantCode = (localStorage.getItem("merchantCode") || "").toUpperCase()
        this.isLogin = !!this.token
        const loginAndRedirect = async () => {
            try {
                const data: any = await this.getUserInfo()
                if (!data) {
                    window.location.hash = "#/"
                    return
                }
                if (localStorage.getItem("isSwitchableUser") === "true") {
                    const companyCodeStr = getCompanyCodeStr()
                    if (companyCodeStr) {
                        workStore.setMultiWebsiteInfo({
                            isPlatformAdmin: true,
                        })
                    } else {
                        workStore.setMultiWebsiteInfo({
                            isOrganizationAdmin: true,
                        })
                    }
                }
                workStore.setMultiWebsiteInfo({
                    merchantList: get(data, "merchantList", []),
                })
                if (this.merchantCode) {
                    EventEmitter.emit("loopGetNotification", {})
                }
                workStore.refreshMerchantInfo()
                const nowHash = window.location.hash?.replace("#", "")
                if (nowHash === "" || nowHash === "/") {
                    if (this.merchantCode) {
                        window.location.hash = "#/dashboard"
                    }
                }
            } catch (e) {
                console.log("e", e)
                window.location.hash = "#/"
            }
        }
        if (this.isLogin) {
            console.log("isLogin")
            loginAndRedirect()
        } else {
            console.log("no isLogin")
            window.location.hash = "#/"
        }
        setTimeout(() => {
            console.log(` 状态机 `, toJS(this))
        }, 8000)
    }
    @mobxAction
    refreshMerchantList = async () => {
        const data: any = await this.getUserInfo()
        workStore.setMultiWebsiteInfo({
            merchantList: get(data, "merchantList", []),
        })
    }
    @mobxAction
    refreshMerchantInfo = async () => {
        if (this.merchantCode) {
            const data: any = await Api.post(
                "/merchant/organizationalManage/merchant/member/detail",
                {
                    id: this.merchantCode,
                },
            )
            workStore.setMultiWebsiteInfo({
                currentMerchantInfo: data ?? {},
            })
        }
    }

    @mobxAction
    login = (token: string) => {
        this.setToken(token)
        this.isLogin = true
    }

    @mobxAction
    logout = () => {
        this.setUserInfo({}) // 清空权限，用户定时刷新报错问题
        EventEmitter.emit("clearItemsWhenLogout", {})
        EventEmitter.emit("stopLoopGetNotification", {})
        this.token = ""
        localStorage.removeItem("token")
        this.isLogin = false
        this._isInitUserInfoFinish = false
        this.clearMultiWebsiteInfo()
    }

    @mobxAction
    setNotificationCount = (count: number) => {
        this.notificationCount = count
    }

    @mobxAction
    setMultiWebsiteInfo = (data: {
        companyCode?: string
        merchantCode?: string
        merchantList?: Array<any>
        currentMerchantInfo?: any
        isOrganizationAdmin?: boolean
        isPlatformAdmin?: boolean
    }) => {
        if (data.companyCode) {
            this.companyCode = data.companyCode
        }
        if (data.merchantCode) {
            this.merchantCode = data.merchantCode
        }
        if (data.merchantList) {
            this.merchantList = data.merchantList
        }
        if (data.currentMerchantInfo) {
            this.currentMerchantInfo = data.currentMerchantInfo
        }
        if (data.isOrganizationAdmin) {
            this.isOrganizationAdmin = data.isOrganizationAdmin
        }
        if (data.isPlatformAdmin) {
            this.isPlatformAdmin = data.isPlatformAdmin
        }
    }

    @mobxAction
    clearMultiWebsiteInfo = () => {
        if (!getCompanyCodeStr()) {
            localStorage.removeItem("companyCode")
            this.companyCode = ""
        }
        localStorage.removeItem("merchantCode")
        localStorage.removeItem("isSwitchableUser")
        this.merchantCode = ""
        this.merchantList = []
    }

    @mobxAction
    setImageDomain = (url: string) => {
        this.globalConfig.imageDomain = url
    }

    @mobxAction
    setGlobalEnum = (data: any) => {
        const 内部枚举值 = {} as Record<any, any>
        for (let [key, items] of Object.entries(枚举值)) {
            const map = [] as Array<any>
            for (let [k, v] of Object.entries(items)) {
                if (!Object.keys(items).includes(v)) {
                    map.push({ label: k, value: String(v) })
                }
            }
            内部枚举值[key] = map
        }
        data["内部枚举值"] = 内部枚举值
        this.globalEnum = data
    }

    @mobxAction
    setGlobalEnumKV = (data: any) => {
        const 内部枚举值 = {} as Record<any, any>
        for (let [key, items] of Object.entries(枚举值)) {
            const map = {} as Record<any, any>
            for (let [k, v] of Object.entries(items)) {
                if (!Object.keys(items).includes(v)) {
                    map[String(v)] = k
                }
            }
            内部枚举值[key] = map
        }
        data["内部枚举值"] = 内部枚举值

        this.globalEnumKV = data
    }

    getImageUrl = (url: string, options: FullS3DomainOptions = {}) => {
        const { autoTransform = false, isLocal = false } = options
        if (url === "") {
            return url
        }
        if (url.includes("http")) {
            return url
        }
        if (url.includes("data:image")) {
            return url
        }
        if (autoTransform && this.supportWebp) {
            url = url.replace(/\.jpg|\.png|\.jpeg/, ".webp")
        }
        const fullUrl = `/${url}`.replace("//", "/")
        if (isLocal) {
            return `./images${fullUrl}`
        }
        const s3Domain = this.globalConfig.imageDomain
        const result = replaceDoubleSlashes(`${s3Domain}${fullUrl}`)
        return result
    }

    getFileUrl = (url: string) => {
        return `${this.globalConfig.fileDomain}${String(url).replace(/^\//, "")}`
    }

    checkPerm = (permKey: string) => {
        const userInfo = this.userInfo
        const permIds = get(userInfo, "permCodes", []) as Array<string>
        return permIds.includes(permKey)
    }

    @mobxAction
    setToken = (token: string) => {
        this.token = token
        localStorage.setItem("token", token)
    }

    @mobxAction
    setUserInfo = (userInfo: any) => {
        this.userInfo = userInfo
    }

    @mobxAction
    getUserInfo = async () => {
        const userInfo = await Api.post(`/userManage/userInfo`, {})
        if (userInfo) {
            this.setUserInfo(userInfo)
            this._isInitUserInfoFinish = true
            errorReport.init({ userInfo })
        } else {
            this._isInitUserInfoFinish = false
            // token过期
        }
        return userInfo
    }

    @mobxAction
    refreshCount = async () => {
        const depositOnlineCount = await Api.post(
            "/fundManage/deposit/online/order/process/count",
            {},
        )
        const depositOfflineCount = await Api.post(
            "/fundManage/deposit/company/order/process/count",
            {},
        )
        const withdrawCount = await Api.post("/fundManage/withdraw/order/process/count", {})
        this.withdrawCount = withdrawCount
        this.depositOnlineCount = depositOnlineCount
        this.depositOfflineCount = depositOfflineCount
    }
}

export const workStore = new WorksStore({})
