import * as Sentry from "@sentry/react"
import { get } from "../tools"
import { SentryInitOptions } from "./types"

const ENV = process.env.REACT_APP_ENV

class SentryService {
    isInit = false

    create() {
        if (this.isInit) {
            return
        }
        const reportUrlMap = {
            dev: "https://35f72c72d1e38b4193be0d6c3522edc2@sentry-sandbox.e107.cc/2",
            test: "https://35f72c72d1e38b4193be0d6c3522edc2@sentry-sandbox.e107.cc/2",
            rc: "https://35f72c72d1e38b4193be0d6c3522edc2@sentry-sandbox.e107.cc/2",
            prod: "https://b16f034db885dc4a79504bd1a70a52ba@sentry.e107.cc/5",
        }
        const reportUrl = get(reportUrlMap, `${ENV}`, reportUrlMap.dev)
        Sentry.init({
            dsn: reportUrl,
            integrations: [
                new Sentry.BrowserTracing({
                    // 控制哪些域名需要追踪
                    tracePropagationTargets: [/https?:\/\/.*/],
                }),
                new Sentry.Replay({
                    maskAllText: true,
                    blockAllMedia: true,
                }),
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, // Capture 100% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.3, // 表示只有30%的用户会被重放
            replaysOnErrorSampleRate: 1.0, // 记录错误的概率
        })

        this.isInit = true
    }

    captureException(error: Error) {
        Sentry.captureException(error)
    }

    init = (params: SentryInitOptions) => {
        Sentry.setUser({
            username: params.userInfo.username,
            id: params.userInfo.id,
        })
        Sentry.setContext(`${ENV}_userContent`, {
            ...params,
            domain: window.location.href,
            Navigator: {
                ...window.navigator,
            },
        })
    }

    report = (obj: Record<string, any>, msg: string) => {
        if (ENV === "dev") {
            return
        }
        Sentry.addBreadcrumb(obj)
        Sentry.captureException(msg)
    }
}

const errorReport = new SentryService()

export default errorReport
