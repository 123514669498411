import React, { lazy, useEffect } from 'react'
import './App.scss'
import { Provider } from "mobx-react"
import { workStore } from "@/store"
import { ConfigProvider } from "antd"
import zhCN from 'antd/locale/zh_CN'
import enUs from 'antd/locale/en_US'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import 'dayjs/locale/zh-cn'
import errorReport from './utils/sentry'

// element已改为 https://element-plus.org/

errorReport.create()

const localeMap: Record<string, any> = {
    'zh': {
        dayjsLocale: 'zh-cn',
        antdLocale: zhCN,
    },
    'en': {
        dayjsLocale: 'en',
        antdLocale: enUs,
    },
}
const localLang = (localStorage.getItem("lang") ?? 'zh')
const locale = localeMap[localLang]

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.locale(locale.dayjsLocale)


const BeforeRoute = lazy(() => import("@/views/BeforeRoute"))

function App() {
    return (
        <Provider workStore={workStore}>
            <ConfigProvider locale={locale.antdLocale}>
                <BeforeRoute></BeforeRoute>
            </ConfigProvider>
        </Provider>
    )
}

export default App
